import { Album } from './models/album.model';
import { Injectable } from '@angular/core';
import { AngularFirestore } from '@angular/fire/firestore';

@Injectable({
  providedIn: 'root'
})
export class DataService {

  constructor(private firestore: AngularFirestore) {

  }

  public getAlbums(){
    return this.firestore.collection<Album>("albums").get()
  }

  public albumRef(){
    return this.firestore.collection<Album>("albums").ref
  }

  saveAlbum(album:Album){
    return this.firestore
            .collection<Album>("albums")
            .add(JSON.parse(JSON.stringify(album)));
  }

}
