import { HttpClient, HttpHeaders } from '@angular/common/http';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class HttpService {

  constructor(private http: HttpClient) { }

  get(url){
    return this.http.get(url);
  }
  post(url,body){
    return this.http.post(url,body, {headers : new HttpHeaders({ 'Content-Type': 'application/json' })});
  }
}
