import { Album, AlbumConverter } from './../models/album.model';
import { DataService } from './../data.service';
import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-list',
  templateUrl: './list.component.html',
  styleUrls: ['./list.component.scss']
})


export class ListComponent implements OnInit {

  public albums:Album[];
  public matches:Album[];

  public genres:string[];
  public styles:string[];

  public limit:number = 20;

  showall:boolean = false;

  public filter = {
    genre : null,
    style : null,
    query: null
  }

  public sorting:string = "artist";

  constructor(private dataService: DataService) { }

  ngOnInit() {
    this.getData()
  }

  getData(){

    this.dataService.albumRef().withConverter(new AlbumConverter).get().then(res=>{
      this.genres = [];
      this.styles = [];
      this.albums = [];
      res.docs.forEach(doc => {
        let data:Album = doc.data();
        data.genre.forEach(genre => {
          if( this.genres.indexOf(genre) < 0 ){
            this.genres.push(genre)
          }
        });
        data.style.forEach(style => {
          if( this.styles.indexOf(style) < 0 ){
            this.styles.push(style)
          }
        });
        this.albums.push(data)
      });
      this.getMatches()
    })
  }

  getMatches(){
    this.matches = this.albums.filter((album,index)=>{
      if(this.filter.genre && album.genre.indexOf(this.filter.genre) < 0){
        return false;
      }
      if(this.filter.style && album.style.indexOf(this.filter.style) < 0){
        return false;
      }
      if( this.filter.query && !album.artist.toLowerCase().match(this.filter.query.toLowerCase()) && !album.title.toLowerCase().match(this.filter.query.toLowerCase()) ){
        return false
      }
      return true;
    }).sort((a,b)=>{
      if( !a[this.sorting] && !b[this.sorting]){
        return 0
      }
      if( !a[this.sorting]){
        return 1
      }
      if( !b[this.sorting]){
        return -1
      }
      return a[this.sorting] < b[this.sorting] ? -1 : 1
    })

  }

  getGenre(genre){
    this.filter.genre = genre;
    this.showall = false;
    this.getMatches()
  }

  getStyle(style){
    this.filter.style = style;
    this.showall = false;
    this.getMatches()
  }

  setSorting(sorting){
    this.sorting = sorting;
    this.getMatches()
  }

  showAll(){
    this.showall = true;
  }

  clearFilter(){
    this.filter.style = null;
    this.filter.genre = null;
    this.filter.query = null;
    this.getMatches()
  }

  doQuery(e){
    this.showall = this.filter.query;
    this.getMatches()
  }

}
