import { HttpService } from './http.service';
import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class DiscogsService {

  private base:string = "https://api.discogs.com/database/search?";
  private token:string = environment.discogs;

  constructor(private http:HttpService) { }

  getAlbumData(query:string){
    return this.http.get(`${this.base}q=${query}&token=${this.token}&format=Vinyl`)
  }
}
