import { environment } from './../environments/environment';
import { Injectable } from '@angular/core';
import { HttpService } from './http.service';

@Injectable({
  providedIn: 'root'
})
export class GvisionService {

  private base: string = "https://vision.googleapis.com/v1/images:annotate?";
  private key: string = environment.gvision;

  constructor(private httpService: HttpService) { }


  getByCover(blob: string) {
    let data = {
      "requests": [
        {
          "image": {
            "content": blob
          },
          "features": [
            {
              "type": "WEB_DETECTION",
              "maxResults": 1
            }
          ]
        }
      ]
    }
    return this.httpService.post(`${this.base}key=${this.key}`, data)
  }
}
