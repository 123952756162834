// This file can be replaced during build by using the `fileReplacements` array.
// `ng build --prod` replaces `environment.ts` with `environment.prod.ts`.
// The list of file replacements can be found in `angular.json`.

export const environment = {
  production: false.valueOf,
  gvision: "AIzaSyCXDYalvEV5qRrkT-LAv-nOLfO84-kmUlo",
  discogs: "HeBdfisshLyzDrtYoedgfJfWpifCmmAQzLhEXWMm",
  firebaseConfig: {
    apiKey: "AIzaSyCXDYalvEV5qRrkT-LAv-nOLfO84-kmUlo",
    authDomain: "plattensammlung.firebaseapp.com",
    databaseURL: "https://plattensammlung-default-rtdb.europe-west1.firebasedatabase.app",
    projectId: "plattensammlung",
    storageBucket: "plattensammlung.appspot.com",
    messagingSenderId: "411471982079",
    appId: "1:411471982079:web:3c5b1d65ac6dbb35198bca"
  }
  
};

/*
 * For easier debugging in development mode, you can import the following file
 * to ignore zone related error stack frames such as `zone.run`, `zoneDelegate.invokeTask`.
 *
 * This import should be commented out in production mode because it will have a negative impact
 * on performance if an error is thrown.
 */
// import 'zone.js/dist/zone-error';  // Included with Angular CLI.
