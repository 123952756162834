export class Album{
    title:string;
    year:string;
    cover:string;
    genre:string[]
    style:string[]
    created:number
    discogs_id:string
    barcode:any[]
    artist:string

    constructor(data){
        this.title = data.title;
        this.year = data.year;
        this.cover = data.cover;
        this.genre = data.genre;
        this.style = data.style;
        this.created = data.created;
        this.discogs_id = data.discogs_id;
        this.barcode = data.barcode;
        this.artist = data.artist;
    }

}

export class AlbumConverter{

    toFirestore(album) {
        return {
            title:album.title,
            year:album.year,
            cover:album.cover,
            genre:album.genre,
            style:album.style,
            created:album.created,
            discogs_id:album.discogs_id,
            barcode:album.barcode,
            artist:album.artist
        };
    }

    fromFirestore(snapshot, options){
        const data = snapshot.data(options);
        return new Album(data);
    }
}