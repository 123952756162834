import { GvisionService } from './gvision.service';
import { DiscogsService } from './discogs.service';
import { HttpService } from './http.service';
import { BrowserModule } from '@angular/platform-browser';
import { NgModule } from '@angular/core';

import { AppRoutingModule } from './app-routing.module';
import { AppComponent } from './app.component';
import { ReaderComponent } from './reader/reader.component';
import { ListComponent } from './list/list.component';

import { HttpClientModule } from '@angular/common/http';

import { environment } from "src/environments/environment";
import { AngularFireModule } from "@angular/fire";
import { AngularFirestoreModule } from "@angular/fire/firestore";
import { FormsModule } from '@angular/forms';

@NgModule({
  declarations: [
    AppComponent,
    ReaderComponent,
    ListComponent
  ],
  imports: [
    BrowserModule,
    HttpClientModule,
    AppRoutingModule,
    AngularFireModule.initializeApp(environment.firebaseConfig),
    AngularFirestoreModule,
    FormsModule
  ],
  providers: [
    HttpService,
    DiscogsService,
    GvisionService
  ],
  bootstrap: [AppComponent]
})
export class AppModule { }
