import { DataService } from './../data.service';
import { Album } from './../models/album.model';
import { DiscogsService } from './../discogs.service';
import { GvisionService } from './../gvision.service';
import { Component, ElementRef, OnInit, ViewChild } from '@angular/core';

@Component({
  selector: 'app-reader',
  templateUrl: './reader.component.html',
  styleUrls: ['./reader.component.scss']
})
export class ReaderComponent implements OnInit {

  @ViewChild("queryInput",{
    static:false
  })
  input: ElementRef;

  
  public detectedQuery:string;
  public noResult:boolean = false;
  public loading:boolean = false;

  public inputImage:string;

  public albumResults:Album[];

  constructor(
    private gvision: GvisionService,
    private discogs: DiscogsService,
    private data: DataService
    ) { }



  ngOnInit() {
  }

  openFile(event) {
    this.loading = true;
    let input = event.target;
    for (var index = 0; index < input.files.length; index++) {
      let reader = new FileReader();
      reader.onload = () => {

        this.prepareImage(reader.result);
      }
      reader.readAsDataURL(input.files[index]);
    };
  }

  prepareImage(data){

    let factor = 0.5;

    const cvs = document.createElement('canvas');
    let img = new Image()
    img.onload = () =>{
      cvs.width = img.width * factor;
      cvs.height = img.height * factor;
      const ctx = cvs.getContext('2d');
      ctx.drawImage(img, 0, 0, img.width * factor, img.height * factor);
      const data = ctx.canvas.toDataURL();
      this.inputImage = data;
      let d = data.split(',')[1]
      this.getVisionData(d);
    };
    img.src = data;


  }

  getVisionData(data){
    this.gvision.getByCover(data).subscribe((res)=>{
      if(res["responses"] && res["responses"].length > 0 && res["responses"][0].webDetection.bestGuessLabels.length > 0 ){
        this.noResult = false;
        this.detectedQuery = res["responses"][0].webDetection.bestGuessLabels[0].label
      } else {
        this.noResult = true;
        this.loading = false;
      }
    })
  }

  getAlbumData(){
    this.albumResults = []
    this.loading = true;
    this.discogs.getAlbumData(this.detectedQuery).subscribe(res=>{
      if( res["results"] ){
        res["results"].forEach(r => {
          let album = new Album({});
          album.title = r.title;
          album.year = r.year;
          album.genre = r.genre;
          album.style = r.style;
          album.cover = r.cover_image
          album.created = Date.now();
          album.discogs_id = r.id;
          album.barcode = r.barcode;

          this.albumResults.push(album);
        });
        this.noResult = false;
      } else {
        this.noResult = true;
      }
      this.loading = false;
    })
  }

  runQuery(){
    this.inputImage = null;
    this.getAlbumData();
  }

  save(album){
    this.data.saveAlbum(album).then(()=>{
      this.clear();
      this.input.nativeElement.focus()
    })
  }

  clear(){
    this.albumResults=null;
    this.detectedQuery = null;
    this.inputImage = null;
  }

}
